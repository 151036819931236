import { createSlice } from "@reduxjs/toolkit";

const usersRoleSlice = createSlice({
    name: "userRole",
    initialState: [],
    reducers: {
        roleList: (state, action) => {
            return action.payload
        },
    },
});

export const { roleList } = usersRoleSlice.actions;
export default usersRoleSlice.reducer;