import styled from "styled-components";
import { Col } from "antd";
import {
  BookOutlined,
  ControlOutlined,
  ShopOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

export const ChartCol = styled(Col)`
  margin: 29px 0px;
`;

export const AddUserIcon = styled(UserAddOutlined)`
  font-size: 25px;
  background: #454545;
  padding: 7px;
  color: #fff;
  border-radius: 4px;
`;

export const AddSchoolIcon = styled(ShopOutlined)`
  font-size: 25px;
  background: #454545;
  padding: 7px;
  color: #fff;
  border-radius: 4px;
`;

export const AddSubjectIcon = styled(BookOutlined)`
  font-size: 25px;
  background: #454545;
  padding: 7px;
  color: #fff;
  border-radius: 4px;
`;

export const AddRoleIcon = styled(ControlOutlined)`
  font-size: 25px;
  background: #454545;
  padding: 7px;
  color: #fff;
  border-radius: 4px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 18px;
  margin: 8px 0;
`;
