import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Upload,
  message,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CreateContentBox, StyledForm } from "./Content.styles";
import { useDispatch, useSelector } from "react-redux";
import {
  useDeleteContentSectionMutation,
  useGetAllContentMutation,
  // useUpdateContentMutation,
} from "../../common/services/content";
import { masterContentSeletedValue } from "../../common/features/contentSlice";
import { toast } from "react-toastify";
import { useGetAllQuizMutation } from "../../common/services/quiz";
import { useGetAllTestMutation } from "../../common/services/test";
import {
  validationCharactersAlphabets,
  validationLink,
  validationOnlyNumbers,
} from "../../constants";
import axios from "axios";

const EditContent = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [contentLoader, setContentLoader] = useState(false);
  //   const [contentId, setContentId] = useState(null);

  const [getContent, { data: contentData }] = useGetAllContentMutation();
  const [getAllQuiz, { data: getAllQuizList }] = useGetAllQuizMutation();
  const [getAllTest, { data: getAllTestList }] = useGetAllTestMutation();

  // const [updateContentMute] = useUpdateContentMutation();
  const [deleteContent] = useDeleteContentSectionMutation();
  const [contents, setContents] = useState([
    { order: "", title: "", description: "", type: "" },
  ]);

  const data = useSelector((state) => state.getAllMaster);
  const user = useSelector((state) => state.userDetails);

  useEffect(() => {
    // setContentId(id);
    const contentById = {
      id: id,
    };
    getContent(contentById);
  }, [id]);

  useEffect(() => {
    if (contentData && contentData.contents) {
      const {
        medium,
        standard,
        school,
        board,
        chapter,
        section,
        subject,
        contents,
      } = contentData.contents[0];

      form.setFieldsValue({
        boardId: board ? board._id : undefined,
        schoolId: school ? school._id : undefined,
        mediumId: medium ? medium._id : undefined,
        standardId: standard ? standard.standardName : undefined,
        chapterId: chapter ? chapter.chapterName : undefined,
        sectionId: section ? section.sectionName : undefined,
        subjectId: subject ? subject.subjectName : undefined,
        contents: contents.map((content) => ({
          order: content.order,
          title: content.title,
          description: content.description,
          _id: content._id,
          type: content.type,
          ...(content.type === "hyperlink" && {
            hyperlink: content?.hyperlink,
          }),
          ...(content.type === "quiz" && { quiz: content?.quiz || undefined }),
          ...(content.type === "test" && { test: content?.test || undefined }),
          ...(content.type === "media" && {
            mediafile: [
              {
                uid: content.mediafile._id,
                name: content.title,
                status: "done",
                url: `https://ilearnbackend.s3.ap-south-1.amazonaws.com/${content.mediafile.path}`,
              },
            ],
          }),
        })),
      });
      const currentSectionId = {
        sectionId: section ? section._id : undefined,
      };
      const currentChapterId={
        chapterId: chapter ? chapter._id : undefined,
      }
      getAllQuiz(currentSectionId);
      getAllTest(currentChapterId);
      setContents(contents);
      setIsContentVisible(true);
    }
  }, [contentData, form]);

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };
    dispatch(masterContentSeletedValue(newSelection));

    if (type === "mediumId") {
      const standard = data?.standards?.filter(
        (standard) => standard.medium === value
      );
      setFilteredData((prevData) => ({ ...prevData, standard }));
    }
    if (type === "standardId") {
      const subject = data?.subjects?.filter(
        (subject) => subject.standard === value
      );
      setFilteredData((prevData) => ({ ...prevData, subject }));
    }
    if (type === "subjectId") {
      const chapter = data?.chapters?.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredData((prevData) => ({ ...prevData, chapter }));
    }
    if (type === "chapterId") {
      const section = data?.sections?.filter(
        (section) => section.chapter === value
      );
      setFilteredData((prevData) => ({ ...prevData, section }));
    }
  };

  const handleClickApply = (value) => {
    setIsContentVisible(true);
  };

  const onFinish = async (values) => {
    setContentLoader(true);
    try {
      for (const content of values.contents) {
        console.log(content, "content update content check id");
        const formData = new FormData();
        formData.append(`id`, id);
        formData.append(`contentobjId`, content?._id);
        formData.append(`order`, Number(content.order));
        formData.append(`title`, content.title);
        formData.append(`description`, content.description);
        formData.append(`type`, content.type);

        if (content.type === "hyperlink") {
          formData.append(`hyperlink`, content.hyperlink);
        } else if (content.type === "media" && content?.mediafile) {
          formData.append(
            `mediafile`,
            content?.mediafile[0]?.originFileObj
              ? content?.mediafile[0]?.originFileObj
              : content?.mediafile[0]?.uid
          );
        } else if (content.type === "test") {
          formData.append(`test`, content.test);
        } else if (content.type === "quiz") {
          formData.append(`quiz`, content.quiz);
        }

        const formCheck = Object.fromEntries(formData.entries());
        console.log(formCheck, "formData content update check");

        const response = await axios.put(
          `${process.env.REACT_APP_BASE_URL}/v1/contentservice/api/content/updateMediaContent`,
          // `http://localhost:8002/api/content/updateMediaContent`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        console.log(response, "response check update content");
        setContentLoader(false);
        toast.success(response?.data?.message);
        
        // const response = await updateContentMute(formData);
        // toast.success(`${content.title} - ${response.data.message}`);
        // setContentLoader(false);
        // if (response.error) {
        //   toast.error(`${content.title} - ${response.error.data.message}`);
        //   setContentLoader(false);
        // }
      }

      navigate(`/content`);
    } catch (error) {
      console.error("Error:", error);
      setContentLoader(false);
    }
  };

  const handleAddContent = () => {
    setContents([
      ...contents,
      { order: "", title: "", description: "", type: "" },
    ]);
  };

  const handleRemoveContent = async (index) => {
    const { _id } = contents[index];
    const newContents = [...contents];
    newContents.splice(index, 1);
    setContents(newContents);
    try {
      const contentId = {
        contentobjId: _id,
      };
      const response = await deleteContent(contentId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      handleCallGetAPI();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const handleTypeChange = (value, index) => {
    const newContents = contents.map((content, i) => {
      if (i === index) {
        return {
          ...content,
          type: value,
          selectedType: value,
        };
      }
      return content;
    });
    setContents(newContents);
  };

  return (
    <>
      <h2>Update Content</h2>
      <StyledForm form={form} onFinish={handleClickApply} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="boardId"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("boardId", value)}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="schoolId"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("schoolId", value)}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mediumId"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("mediumId", value)}
                disabled
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standardId"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standardId", value)}
                disabled
              >
                {filteredData?.standard?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="subjectId"
              label="Subject"
              rules={[
                { required: true, message: "Please select your subject" },
              ]}
            >
              <Select
                placeholder="Select a Subject"
                onChange={(value) => handleSelectionChange("subjectId", value)}
                disabled
              >
                {filteredData?.subject?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.subjectName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="chapterId"
              label="Chapter"
              rules={[
                { required: true, message: "Please select your chapter" },
              ]}
            >
              <Select
                placeholder="Select a Chapter"
                onChange={(value) => handleSelectionChange("chapterId", value)}
                disabled
              >
                {filteredData?.chapter?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.chapterName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="sectionId"
              label="Section"
              rules={[
                { required: true, message: "Please select your section" },
              ]}
            >
              <Select
                placeholder="Select a Section"
                onChange={(value) => handleSelectionChange("sectionId", value)}
                disabled
              >
                {filteredData?.section?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.sectionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1f292b", color: "#fff" }}
            type="primary"
            htmlType="submit"
            disabled
          >
            Apply
          </Button>
        </Form.Item>
      </StyledForm>
      {isContentVisible && (
        <>
          <Spin spinning={contentLoader} fullscreen />
          <StyledForm form={form} onFinish={onFinish} layout="vertical">
            {contents.map((content, index) => (
              <CreateContentBox key={index}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      style={{ display: "none" }}
                      name={["contents", index, "_id"]}
                      label="ID"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      name={["contents", index, "order"]}
                      label="Order"
                      required
                      rules={[
                        {
                          required: false,
                          message: "Please enter order",
                        },
                        {
                          validator: (rule, value, callback) =>
                            validationOnlyNumbers(
                              rule,
                              value,
                              callback,
                              "Please enter a valid order"
                            ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "title"]}
                      label="Title"
                      required
                      rules={[
                        {
                          required: false,
                          message: "Please enter title",
                        },
                        {
                          validator: (rule, value, callback) =>
                            validationCharactersAlphabets(
                              rule,
                              value,
                              callback,
                              "Please enter a valid title"
                            ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "description"]}
                      label="Description"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "type"]}
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select type",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a File type"
                        onChange={(value) => handleTypeChange(value, index)}
                      >
                        <Option value="media">Media</Option>
                        <Option value="hyperlink">Link</Option>
                        <Option value="quiz">Quiz</Option>
                        <Option value="test">Test</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {content.type === "media" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "mediafile"]}
                        label="Media File"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please upload media file",
                          },
                        ]}
                      >
                        <Upload
                          beforeUpload={() => false}
                          maxCount={1}
                          accept="video/*, application/pdf"
                          onChange={(info) => {
                            const { status } = info.file;
                            if (status === "done") {
                              message.success(
                                `${info.file.name} file uploaded successfully.`
                              );
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                        >
                          <Button>Upload Media</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "hyperlink" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "hyperlink"]}
                        label="Link"
                        rules={[
                          {
                            required: false,
                            message: "Please enter link",
                          },
                          {
                            validator: (rule, value, callback) =>
                              validationLink(
                                rule,
                                value,
                                callback,
                                "Please enter a valid link"
                              ),
                          },
                        ]}
                      >
                        <Input placeholder="https://example.com" />
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "quiz" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "quiz"]}
                        label="Quiz"
                        rules={[
                          {
                            required: true,
                            message: "Please select your quiz",
                          },
                        ]}
                      >
                        <Select placeholder="Select a Quiz">
                          {getAllQuizList?.quizzes.map((item) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "test" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "test"]}
                        label="Test"
                        rules={[
                          {
                            required: true,
                            message: "Please select your test",
                          },
                        ]}
                      >
                        <Select placeholder="Select a test">
                          {getAllTestList?.tests.map((item) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {contents.length > 1 && (
                  <Button
                    type="dashed"
                    onClick={() => handleRemoveContent(index)}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                    block
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Content
                  </Button>
                )}
                {index === contents.length - 1 && (
                  <Button
                    type="dashed"
                    onClick={handleAddContent}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Content
                  </Button>
                )}
              </CreateContentBox>
            ))}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Save contents
              </Button>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  <Button
                    onClick={() => navigate("/content")}
                    style={{ marginLeft: 8 }}
                    block
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => navigate("/content")}
                  style={{ marginLeft: 8 }}
                  block
                >
                  Back
                </Button>
              </Col>
            </Row>
          </StyledForm>
        </>
      )}
    </>
  );
};

export default EditContent;
