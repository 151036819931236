import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import {
  useDeleteStandardMutation,
  useGetAllStandardMutation,
} from "../../common/services/standard";
import { PageHeader } from "./Standard.styles";
import { useSelector } from "react-redux";
import { Button, Input } from "antd";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const Standard = () => {
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [getAllStandard, { data, isLoading }] = useGetAllStandardMutation();
  const [deleteStandard] = useDeleteStandardMutation();

  const user = useSelector((state) => state.userDetails);

  // console.log(data, "data check standard");

  useEffect(() => {
    if (user.school) {
      handleCallGetAPI();
    }
  }, [user]);

  const handleCallGetAPI = () => {
    const currentSchoolId = {
      schoolId: user.school._id,
    };
    getAllStandard(currentSchoolId);
  };

  const columns = [
    {
      title: "Standard Name",
      dataIndex: "standardName",
      key: "standardName",
    },
    {
      title: "Standard ID",
      dataIndex: "standardId",
      key: "standardId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (medium) => <>{medium.mediumName}</>,
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
      render: (school) => <>{school.schoolName}</>,
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (school) => <>{school.fullname}</>,
    },
  ];

  const handleCreateStandardClick = () => {
    navigate(`/standard/create`);
  };

  const handleEdit = (standard) => {
    navigate(`/standard/edit/${standard._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const standardId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteStandard(standardId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      handleCallGetAPI();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "standard", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "standard", action: "delete" },
    },
  ];

  const filteredStandards = data?.data?.filter(
    (standard) =>
      standard?.standardName
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      standard?.standardId
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      standard?.description
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      standard?.school?.schoolName
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
      standard?.createdBy?.fullname
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Standard List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="standard" action="create">
          <Button onClick={handleCreateStandardClick}>Create Standard</Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={
          filteredStandards &&
          [...filteredStandards].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Standard"}
        description={"Are you sure you want to delete this standard?"}
      />
    </>
  );
};

export default Standard;
