import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  Upload,
  message,
  Spin,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { CreateContentBox, StyledForm } from "./Content.styles";
import { useDispatch, useSelector } from "react-redux";
import { useCreateContentMutation } from "../../common/services/content";
import { masterContentSeletedValue } from "../../common/features/contentSlice";
import { toast } from "react-toastify";
import { useGetAllQuizMutation } from "../../common/services/quiz";
import { useGetAllTestMutation } from "../../common/services/test";
import {
  validationCharactersAlphabets,
  validationLink,
  validationOnlyNumbers,
} from "../../constants";

const CreateContent = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isContentVisible, setIsContentVisible] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [contentLoader, setContentLoader] = useState(false);

  const [createContentMute] = useCreateContentMutation();
  const [getAllQuiz, { data: getAllQuizList }] = useGetAllQuizMutation();
  const [getAllTest, { data: getAllTestList }] = useGetAllTestMutation();
  const [contents, setContents] = useState([
    { order: "", title: "", description: "", type: "" },
  ]);

  const data = useSelector((state) => state.getAllMaster);
  const user = useSelector((state) => state.userDetails);
  const masterContent = useSelector((state) => state.masterContentSeletedValue);

  console.log(data, "master data check");

  useEffect(() => {
    const sectionId =
      masterContent.length > 0 &&
      masterContent.filter((item) => item.type === "sectionId");
    const chapterId =
      masterContent.length > 0 &&
      masterContent.filter((item) => item.type === "chapterId");
    if (sectionId.length) {
      const currentSectionId = {
        sectionId: sectionId[0]?.value,
      };
      const currentChapterId = {
        chapterId: chapterId[0]?.value,
      };
      getAllQuiz(currentSectionId);
      getAllTest(currentChapterId);
    }
  }, [masterContent]);

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        schoolId: user.school._id,
      });
    }
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        boardId: user.school.board[0]._id,
      });
    }
  }, [user, form]);

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };
    dispatch(masterContentSeletedValue(newSelection));

    if (type === "mediumId") {
      const standard = data?.standards?.filter(
        (standard) => standard.medium === value
      );
      setFilteredData((prevData) => ({ ...prevData, standard }));
    }
    if (type === "standardId") {
      const subject = data?.subjects?.filter(
        (subject) => subject.standard === value
      );
      setFilteredData((prevData) => ({ ...prevData, subject }));
    }
    if (type === "subjectId") {
      const chapter = data?.chapters?.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredData((prevData) => ({ ...prevData, chapter }));
    }
    if (type === "chapterId") {
      const section = data?.sections?.filter(
        (section) => section.chapter === value
      );
      setFilteredData((prevData) => ({ ...prevData, section }));
    }
  };

  const handleClickApply = (value) => {
    setIsContentVisible(true);
  };

  const onFinish = async (values) => {
    setContentLoader(true);
    const formData = new FormData();

    masterContent.map((item) => {
      formData.append(item.type, item.value);
    });

    values.contents.forEach((content, index) => {
      console.log(content, "content check");

      formData.append(`contents[${index}][order]`, content?.order);
      formData.append(`contents[${index}][title]`, content?.title);
      formData.append(`contents[${index}][description]`, content?.description);
      formData.append(`contents[${index}][type]`, content?.type);

      if (content.type === "hyperlink") {
        formData.append(`contents[${index}][hyperlink]`, content?.hyperlink);
      } else if (content.type === "media") {
        formData.append(
          `mediafile${index}`,
          content.mediafile[0].originFileObj
        );
      } else if (content.type === "test") {
        formData.append(`contents[${index}][test]`, content?.test);
      } else if (content.type === "quiz") {
        formData.append(`contents[${index}][quiz]`, content?.quiz);
      }
    });

    formData.append(`schoolId`, user?.school?._id);
    formData.append(`boardId`, user?.school?.board[0]?._id);

    try {
      const response = await createContentMute(formData);
      console.log(response, "create content response check");
      if (response.data) {
        toast.success(response.data.message);
        setContentLoader(false);
      }
      if (response.error) {
        toast.error(response.error.message);
        setContentLoader(false);
      }
      navigate(`/content`);
    } catch (error) {
      console.error("Login error:", error);
      setContentLoader(false);
    }
  };

  const handleAddContent = () => {
    setContents([
      ...contents,
      { order: "", title: "", description: "", type: "" },
    ]);
  };

  const handleRemoveContent = (index) => {
    const newContents = [...contents];
    newContents.splice(index, 1);
    setContents(newContents);
  };

  const handleTypeChange = (value, index) => {
    const newContents = [...contents];
    newContents[index].type = value;
    newContents[index].selectedType = value;
    setContents(newContents);
  };

  return (
    <>
      <h2>Create Content</h2>
      <StyledForm form={form} onFinish={handleClickApply} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="boardId"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("boardId", value)}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="schoolId"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("schoolId", value)}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mediumId"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("mediumId", value)}
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standardId"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standardId", value)}
                disabled={!form.getFieldValue("mediumId")}
              >
                {filteredData?.standard?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="subjectId"
              label="Subject"
              rules={[
                { required: true, message: "Please select your subject" },
              ]}
            >
              <Select
                placeholder="Select a Subject"
                onChange={(value) => handleSelectionChange("subjectId", value)}
                disabled={!form.getFieldValue("standardId")}
              >
                {filteredData?.subject?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.subjectName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="chapterId"
              label="Chapter"
              rules={[
                { required: true, message: "Please select your chapter" },
              ]}
            >
              <Select
                placeholder="Select a Chapter"
                onChange={(value) => handleSelectionChange("chapterId", value)}
                disabled={!form.getFieldValue("subjectId")}
              >
                {filteredData?.chapter?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.chapterName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="sectionId"
              label="Section"
              rules={[
                { required: true, message: "Please select your section" },
              ]}
            >
              <Select
                placeholder="Select a Section"
                onChange={(value) => handleSelectionChange("sectionId", value)}
                disabled={!form.getFieldValue("chapterId")}
              >
                {filteredData?.section?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.sectionName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1f292b" }}
            type="primary"
            htmlType="submit"
          >
            Apply
          </Button>
        </Form.Item>
      </StyledForm>
      {isContentVisible && (
        <>
          <Spin spinning={contentLoader} fullscreen />
          <StyledForm form={form} onFinish={onFinish} layout="vertical">
            {contents.map((content, index) => (
              <CreateContentBox key={index}>
                <Row gutter={24}>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "order"]}
                      label="Order"
                      rules={[
                        {
                          required: false,
                          message: "Please enter order",
                        },
                        {
                          validator: (rule, value, callback) =>
                            validationOnlyNumbers(
                              rule,
                              value,
                              callback,
                              "Please enter a valid order"
                            ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "title"]}
                      label="Title"
                      required
                      rules={[
                        {
                          required: false,
                          message: "Please enter title",
                        },
                        {
                          validator: (rule, value, callback) =>
                            validationCharactersAlphabets(
                              rule,
                              value,
                              callback,
                              "Please enter a valid title"
                            ),
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "description"]}
                      label="Description"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={["contents", index, "type"]}
                      label="Type"
                      rules={[
                        {
                          required: true,
                          message: "Please select type",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a File type"
                        onChange={(value) => handleTypeChange(value, index)}
                      >
                        <Option value="media">Media</Option>
                        <Option value="hyperlink">Link</Option>
                        <Option value="quiz">Quiz</Option>
                        <Option value="test">Test</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {content.selectedType === "media" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "mediafile"]}
                        label="Media File"
                        valuePropName="fileList"
                        getValueFromEvent={(e) => {
                          if (Array.isArray(e)) {
                            return e;
                          }
                          return e && e.fileList;
                        }}
                        rules={[
                          {
                            required: true,
                            message: "Please upload media file",
                          },
                        ]}
                      >
                        <Upload
                          beforeUpload={() => false}
                          maxCount={1}
                          accept="video/*, application/pdf"
                          onChange={(info) => {
                            const { status } = info.file;
                            if (status === "done") {
                              message.success(
                                `${info.file.name} file uploaded successfully.`
                              );
                            } else if (status === "error") {
                              message.error(
                                `${info.file.name} file upload failed.`
                              );
                            }
                          }}
                        >
                          <Button>Upload Media</Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "hyperlink" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "hyperlink"]}
                        label="Link"
                        required
                        rules={[
                          {
                            required: false,
                            message: "Please enter link",
                          },
                          {
                            validator: (rule, value, callback) =>
                              validationLink(
                                rule,
                                value,
                                callback,
                                "Please enter a valid link"
                              ),
                          },
                        ]}
                      >
                        <Input placeholder="https://example.com" />
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "quiz" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "quiz"]}
                        label="Quiz"
                        rules={[
                          {
                            required: true,
                            message: "Please select your quiz",
                          },
                        ]}
                      >
                        <Select placeholder="Select a Quiz">
                          {getAllQuizList?.quizzes.map((item) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                  {content.type === "test" && (
                    <Col span={8}>
                      <Form.Item
                        name={["contents", index, "test"]}
                        label="Test"
                        rules={[
                          {
                            required: true,
                            message: "Please select your test",
                          },
                        ]}
                      >
                        <Select placeholder="Select a test">
                          {getAllTestList?.tests.map((item) => (
                            <Option value={item._id} key={item._id}>
                              {item.title}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}
                </Row>
                {contents.length > 1 && (
                  <Button
                    type="dashed"
                    onClick={() => handleRemoveContent(index)}
                    style={{
                      color: "red",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                    block
                    icon={<MinusCircleOutlined />}
                  >
                    Remove Content
                  </Button>
                )}
                {index === contents.length - 1 && (
                  <Button
                    type="dashed"
                    onClick={handleAddContent}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Content
                  </Button>
                )}
              </CreateContentBox>
            ))}
            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Submit all contents
              </Button>
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item>
                  <Button
                    onClick={() => navigate("/content")}
                    style={{ marginLeft: 8 }}
                    block
                  >
                    Cancel
                  </Button>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button
                  onClick={() => navigate("/content")}
                  style={{ marginLeft: 8 }}
                  block
                >
                  Back
                </Button>
              </Col>
            </Row>
          </StyledForm>
        </>
      )}
    </>
  );
};

export default CreateContent;
