import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Button, Select, Row, Col, Transfer } from "antd";
import {
  MarkHeading,
  MarkWrapper,
  OptionWrapper,
  PositiveMark,
  StyledForm,
  TransferWrapper,
} from "./Test.styles";
import { useDispatch, useSelector } from "react-redux";
import { masterContentSeletedValue } from "../../common/features/contentSlice";
import { useUpdateTestMutation } from "../../common/services/test";
import { useGetAllQuestionMutation } from "../../common/services/question";
import { toast } from "react-toastify";
import {
  validationCharactersAlphabets,
  validationOnlyNumbers,
} from "../../constants";

const EditTest = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { Option } = Select;
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isFormVisible, setIsFormVisible] = useState(false);
  const [filteredData, setFilteredData] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [marks, setMarks] = useState({});

  const [updateTestMute] = useUpdateTestMutation();
  const [getAllQuestion, { data: getAllQuestionList }] =
    useGetAllQuestionMutation();
  const data = useSelector((state) => state.getAllMaster);
  const user = useSelector((state) => state.userDetails);
  const testTempDetails = useSelector((state) => state.testTempDetails);

  // console.log(testTempDetails, "testTempDetails")
  useEffect(() => {
    if (testTempDetails) {
      const {
        medium,
        standard,
        school,
        board,
        chapter,
        section,
        subject,
        title,
        totalMarks,
        totalQuestions,
        description,
        randamizeQuestions,
        passingPercentage,
        questions,
      } = testTempDetails;

      form.setFieldsValue({
        boardId: board ? board._id : undefined,
        schoolId: school ? school._id : undefined,
        mediumId: medium ? medium._id : undefined,
        standardId: standard ? standard.standardName : undefined,
        chapterId: chapter ? chapter.chapterName : undefined,
        sectionId: section ? section.sectionName : undefined,
        subjectId: subject ? subject.subjectName : undefined,
        title,
        totalMarks,
        description,
        totalQuestions,
        randamizeQuestions,
        passingPercentage,
        marks: Object.fromEntries(
          questions?.map((question) => [question.question?._id, question?.mark])
        ),
      });
      setIsFormVisible(true);
      const selectedKeys = questions.map((question) => question.question?._id);
      setSelectedKeys(selectedKeys);
      const defaultMarks = {};
      questions.forEach((question) => {
        defaultMarks[question.question?._id] = question.mark;
      });
      setMarks(defaultMarks);
    }
  }, [testTempDetails, form]);

  useEffect(() => {
    if (!getAllQuestionList) {
      const chapterId = testTempDetails && testTempDetails?.chapter?._id;
      const currentSectionId = {
        chapterId: chapterId,
      };
      getAllQuestion(currentSectionId);
    }
  }, [testTempDetails]);

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };
    dispatch(masterContentSeletedValue(newSelection));

    if (type === "mediumId") {
      const standard = data?.standards?.filter(
        (standard) => standard.medium === value
      );
      setFilteredData((prevData) => ({ ...prevData, standard }));
    }
    if (type === "standardId") {
      const subject = data?.subjects?.filter(
        (subject) => subject.standard === value
      );
      setFilteredData((prevData) => ({ ...prevData, subject }));
    }
    if (type === "subjectId") {
      const chapter = data?.chapters?.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredData((prevData) => ({ ...prevData, chapter }));
    }
    if (type === "chapterId") {
      const section = data?.sections?.filter(
        (section) => section.chapter === value
      );
      setFilteredData((prevData) => ({ ...prevData, section }));
    }
  };

  const handleClickApply = () => {
    setIsFormVisible(true);
  };

  const handleChange = (nextTargetKeys) => {
    setSelectedKeys(nextTargetKeys);
  };

  const handleMarkChange = (value, key) => {
    setMarks((prevMarks) => ({ ...prevMarks, [key]: value }));
  };

  const onFinish = async (values) => {
    const {
      title,
      description,
      totalMarks,
      totalQuestions,
      randamizeQuestions,
    } = values;
    const { medium, standard, subject, chapter } = testTempDetails;

    console.log(values, "values");

    const formData = new FormData();
    formData.append("id", id);
    formData.append("mediumId", medium._id);
    formData.append("standardId", standard._id);
    formData.append("subjectId", subject._id);
    formData.append("chapterId", chapter._id);
    // formData.append('sectionId', section._id);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("totalMarks", totalMarks);
    formData.append("totalQuestions", totalQuestions);
    formData.append("randamizeQuestions", randamizeQuestions);

    selectedKeys.forEach((key, index) => {
      formData.append(`questions[${index}][question]`, key);
      formData.append(`questions[${index}][mark]`, marks[key] || "");
    });

    try {
      const response = await updateTestMute(formData);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      navigate(`/test`);
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <>
      <h2>Edit Test</h2>
      <StyledForm form={form} onFinish={handleClickApply} layout="vertical">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="boardId"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("boardId", value)}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="schoolId"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("schoolId", value)}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="mediumId"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("mediumId", value)}
                disabled
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standardId"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standardId", value)}
                disabled
              >
                {filteredData?.standard?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="subjectId"
              label="Subject"
              rules={[
                { required: true, message: "Please select your subject" },
              ]}
            >
              <Select
                placeholder="Select a Subject"
                onChange={(value) => handleSelectionChange("subjectId", value)}
                disabled
              >
                {filteredData?.subject?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.subjectName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="chapterId"
              label="Chapter"
              rules={[
                { required: true, message: "Please select your chapter" },
              ]}
            >
              <Select
                placeholder="Select a Chapter"
                onChange={(value) => handleSelectionChange("chapterId", value)}
                disabled
              >
                {filteredData?.chapter?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.chapterName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={6}>
                        <Form.Item
                            name="sectionId"
                            label="Section"
                            rules={[{ required: true, message: "Please select your section" }]}
                        >
                            <Select placeholder="Select a Section" onChange={(value) => handleSelectionChange('sectionId', value)} disabled>
                                {filteredData?.section?.map((item) => (
                                    <Option value={item._id} key={item._id}>{item.sectionName}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col> */}
        </Row>
        <Form.Item>
          <Button
            style={{ backgroundColor: "#1f292b", color: "#fff" }}
            type="primary"
            htmlType="submit"
            disabled
          >
            Apply
          </Button>
        </Form.Item>
      </StyledForm>
      {isFormVisible && (
        <StyledForm form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="title"
                label="Title"
                required
                rules={[
                  {
                    required: false,
                    message: "Please enter title",
                  },
                  {
                    validator: (rule, value, callback) =>
                      validationCharactersAlphabets(
                        rule,
                        value,
                        callback,
                        "Please enter a valid title"
                      ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item name="description" label="Description">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="totalMarks"
                label="Total Marks"
                required
                rules={[
                  {
                    required: false,
                    message: "Please enter total marks",
                  },
                  {
                    validator: (rule, value, callback) =>
                      validationOnlyNumbers(
                        rule,
                        value,
                        callback,
                        "Please enter a valid total marks"
                      ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="totalQuestions"
                label="Total Questions"
                required
                rules={[
                  {
                    required: false,
                    message: "Please enter total questions",
                  },
                  {
                    validator: (rule, value, callback) =>
                      validationOnlyNumbers(
                        rule,
                        value,
                        callback,
                        "Please enter a valid total questions"
                      ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="randamizeQuestions"
                label="Randamize Questions"
                required
                rules={[
                  {
                    required: false,
                    message: "Please enter randamize questions",
                  },
                  {
                    validator: (rule, value, callback) =>
                      validationOnlyNumbers(
                        rule,
                        value,
                        callback,
                        "Please enter a valid randamize questions"
                      ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                name="passingPercentage"
                label="Passing Percentage"
                required
                rules={[
                  {
                    required: false,
                    message: "Please enter passing percentage",
                  },
                  {
                    validator: (rule, value, callback) =>
                      validationOnlyNumbers(
                        rule,
                        value,
                        callback,
                        "Please enter a valid passing percentage"
                      ),
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {getAllQuestionList?.questions && (
            <OptionWrapper>
              <div>
                <TransferWrapper>
                  <Transfer
                    dataSource={
                      getAllQuestionList &&
                      getAllQuestionList?.questions.map((question, index) => ({
                        key: question._id,
                        question: `(${index + 1}). ${question.question.replace(
                          /<[^>]+>/g,
                          ""
                        )}`,
                      }))
                    }
                    targetKeys={selectedKeys}
                    listStyle={{
                      width: 300,
                      height: 300,
                    }}
                    onChange={handleChange}
                    render={(item) => item.question}
                  />
                  <MarkWrapper>
                    <PositiveMark>
                      <MarkHeading>Marks</MarkHeading>
                      {selectedKeys.map((key,index) => (
                        <div key={key}>
                          <Form.Item
                            style={{ marginBottom: "0" }}
                            name={["marks", key]}
                            required
                            rules={[
                              {
                                required: false,
                                message: "Please enter mark",
                              },
                            //   {
                            //     validator: (rule, value, callback) =>
                            //       validationOnlyNumbers(
                            //         rule,
                            //         value,
                            //         callback,
                            //         "Please enter a valid mark"
                            //       ),
                            //   },
                            ]}
                          >
                          <span style={{ marginLeft: "5px" }}>
                              {`(${index + 1})`}
                            </span>
                            <Input
                              style={{
                                margin: "2px 10px",
                                fontSize: "10px",
                                width: "40px",
                                padding: "5px",
                              }}
                              placeholder="Marks"
                              value={marks[key] || ""}
                              onChange={(e) =>
                                handleMarkChange(e.target.value, key)
                              }
                            />
                          </Form.Item>
                        </div>
                      ))}
                    </PositiveMark>
                    <div></div>
                  </MarkWrapper>
                </TransferWrapper>
              </div>
            </OptionWrapper>
          )}
          <Row gutter={24}>
            <Col span={8}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
                <Button
                  onClick={() => navigate("/test")}
                  style={{ marginLeft: 8 }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => navigate("/test")}
                  style={{ marginLeft: 8 }}
                >
                  Back
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </StyledForm>
      )}
    </>
  );
};

export default EditTest;
