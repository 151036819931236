import { createSlice } from "@reduxjs/toolkit";

const testSlice = createSlice({
    name: "testTempDetails",
    initialState: [],
    reducers: {
        testTempDetails: (state, action) => {
            return action.payload
        },
    },
});

export const { testTempDetails } = testSlice.actions;
export default testSlice.reducer;
