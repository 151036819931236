import React from "react";
import ReactApexChart from "react-apexcharts";
import { Typography } from "antd";
import { MinusOutlined } from "@ant-design/icons";
import { lineChart } from "../config";
import { LineChartWrapper } from "./LineChart.styles";

const LineChart = () => {
  const { Title, Paragraph } = Typography;

  return (
    <>
      <LineChartWrapper>
        <div>
          <Title level={5}>Active Users</Title>
          <Paragraph>
            than last week <span>+30%</span>
          </Paragraph>
        </div>
        <div>
          <ul>
            <li>{<MinusOutlined />} Traffic</li>
            <li>{<MinusOutlined />} Sales</li>
          </ul>
        </div>
      </LineChartWrapper>

      <ReactApexChart
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
};

export default LineChart;
