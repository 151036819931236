import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const boardApi = createApi({
  reducerPath: "boardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/userservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllBoard: builder.mutation({
      query: () => ({
        url: "api/user/getActiveBoards",
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    getIdByBoard: builder.mutation({
      query: (body) => ({
        url: "api/user/getActiveBoards",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createBoard: builder.mutation({
      query: (body) => ({
        url: "api/user/createBoard",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateBoard: builder.mutation({
      query: (body) => ({
        url: "api/user/updateBoard",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteBoard: builder.mutation({
      query: (body) => ({
        url: "api/user/deactivateBoard",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllBoardMutation,
  useCreateBoardMutation,
  useGetIdByBoardMutation,
  useUpdateBoardMutation,
  useDeleteBoardMutation,
} = boardApi;
