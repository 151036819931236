import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Divider } from "antd";
import {
  AppstoreOutlined,
  BankOutlined,
  BookOutlined,
  ContainerOutlined,
  ControlOutlined,
  DashboardOutlined,
  FileImageOutlined,
  FileMarkdownOutlined,
  FileZipOutlined,
  FolderAddOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  NotificationOutlined,
  PicRightOutlined,
  QuestionOutlined,
  ShopOutlined,
  StockOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  LayoutContainer,
  AppLogo,
  LogoWrapper,
  CompanyName,
  Sidebar,
  TopHeader,
  SidebarMenu,
  MenuItem,
  MainContent,
  SidebarSubMenu,
} from "./Layout.styles";
import Logo from "../../images/logo.png";
import UserMenu from "../UserMenu";
// import Notification from "../Notification";
import { useGetUserByIdMutation } from "../../common/services/users";
import { userDetails } from "../../common/features/usersSlice";
import { useDispatch } from "react-redux";
import { useGetAllMasterMutation } from "../../common/services/schools";
import { getAllMaster } from "../../common/features/schoolSlice";
import { permissionsList } from "../../common/features/permissionSlice";

const Layout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [collapsed, setCollapsed] = useState(false);

  const user = JSON.parse(sessionStorage.getItem("details"));
  const token = sessionStorage.getItem("token");

  const [getUserById, { data }] = useGetUserByIdMutation();
  const [getMasterDetails, { data: getMaster }] = useGetAllMasterMutation();

  if (!token) {
    navigate(`/login`);
  }

  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      if (decodedToken.exp * 1000 < Date.now()) {
        navigate(`/login`);
      }
    }
  }, []);

  useEffect(() => {
    const userById = {
      id: user?.id,
    };
    getUserById(userById);
  }, [getUserById]);

  useEffect(() => {
    dispatch(userDetails(data));
    dispatch(permissionsList(data?.User.role?.permissions));
  }, [data]);

  useEffect(() => {
    if (data?.User) {
      const schoolById = {
        schoolId: data?.User?.school?._id,
      };
      getMasterDetails(schoolById);
    }
  }, [data]);

  useEffect(() => {
    dispatch(getAllMaster(getMaster));
  }, [getMaster]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate(`/login`);
  };

  const hasPermission = (permission) => {
    return (
      data?.User &&
      data?.User.role?.permissions &&
      data.User.role.permissions[permission]?.read
    );
  };

  return (
    <>
      <LayoutContainer>
        <Sidebar trigger={null} collapsible collapsed={collapsed}>
          <LogoWrapper>
            <AppLogo src={Logo} alt="logo" />
            {!collapsed && <CompanyName>WeLearn</CompanyName>}
          </LogoWrapper>
          <SidebarMenu mode="inline">
            <MenuItem key="1" icon={<DashboardOutlined />}>
              <Link to="/">Dashboard</Link>
            </MenuItem>
            {hasPermission("user") && (
              <MenuItem key="2" icon={<UserOutlined />}>
                <Link to="/user">User</Link>
              </MenuItem>
            )}
            {hasPermission("student") && (
              <MenuItem key="3" icon={<UserOutlined />}>
                <Link to="/student">Student</Link>
              </MenuItem>
            )}
            {hasPermission("role") && (
              <MenuItem key="4" icon={<ControlOutlined />}>
                <Link to="/role">Role</Link>
              </MenuItem>
            )}
            {hasPermission("school") && (
              <MenuItem key="5" icon={<ShopOutlined />}>
                <Link to="/school">School</Link>
              </MenuItem>
            )}
            {hasPermission("board") && (
              <MenuItem key="6" icon={<BankOutlined />}>
                <Link to="/board">Board</Link>
              </MenuItem>
            )}
            {hasPermission("medium") && (
              <MenuItem key="7" icon={<FileMarkdownOutlined />}>
                <Link to="/medium">Medium</Link>
              </MenuItem>
            )}
            {hasPermission("standard") && (
              <MenuItem key="8" icon={<ContainerOutlined />}>
                <Link to="/standard">Standard</Link>
              </MenuItem>
            )}
            {hasPermission("master") && (
              <MenuItem key="9" icon={<FolderAddOutlined />}>
                <Link to="/master">Master</Link>
              </MenuItem>
            )}
            {hasPermission("content") && (
              <SidebarSubMenu
                key="10"
                title="Content Manager"
                icon={<AppstoreOutlined />}
              >
                <MenuItem key="11" icon={<FileZipOutlined />}>
                  <Link to="/content">Content</Link>
                </MenuItem>
                <MenuItem key="12" icon={<QuestionOutlined />}>
                  <Link to="/question">Question</Link>
                </MenuItem>
                <MenuItem key="13" icon={<StockOutlined />}>
                  <Link to="/quiz">Quiz</Link>
                </MenuItem>
                <MenuItem key="14" icon={<BookOutlined />}>
                  <Link to="/test">Test</Link>
                </MenuItem>
              </SidebarSubMenu>
            )}
            {hasPermission("cms") && (
              <SidebarSubMenu key="15" title="CMS" icon={<PicRightOutlined />}>
                <MenuItem key="16" icon={<FileImageOutlined />}>
                  <Link to="/banner">Banner</Link>
                </MenuItem>
              </SidebarSubMenu>
            )}
            {hasPermission("notification") && (
              <MenuItem key="17" icon={<NotificationOutlined />}>
                <Link to="/notification">Notification</Link>
              </MenuItem>
            )}
           
          </SidebarMenu>
        </Sidebar>
        <LayoutContainer>
          <TopHeader>
            <div>
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "20px" }}
                  onClick={toggleCollapsed}
                />
              ) : (
                <MenuFoldOutlined
                  style={{ fontSize: "20px" }}
                  onClick={toggleCollapsed}
                />
              )}
            </div>
            <div>
              {/* <Notification /> */}
              <Divider type="vertical" />
              <UserMenu onLogout={handleLogout} />
            </div>
          </TopHeader>
          <MainContent>
            <Outlet />
          </MainContent>
        </LayoutContainer>
      </LayoutContainer>
    </>
  );
};

export default Layout;
