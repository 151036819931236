import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const standardApi = createApi({
  reducerPath: "standardApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/userservice`,
    // baseUrl: `http://localhost:8001/`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllStandard: builder.mutation({
      query: (body) => ({
        url: "api/user/getActiveStandards",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createStandard: builder.mutation({
      query: (body) => ({
        url: "api/user/createStandard",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateStandard: builder.mutation({
      query: (body) => ({
        url: "api/user/updateStandard",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteStandard: builder.mutation({
      query: (body) => ({
        url: "api/user/deactivateStandard",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllStandardMutation,
  useCreateStandardMutation,
  useUpdateStandardMutation,
  useDeleteStandardMutation,
} = standardApi;
