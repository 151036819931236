import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const sectionApi = createApi({
  reducerPath: "sectionApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://localhost:8001/`,
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/userservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllSection: builder.mutation({
      query: (body) => ({
        url: "api/user/getActiveSections",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createSection: builder.mutation({
      query: (body) => ({
        url: "api/user/createSection",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateSection: builder.mutation({
      query: (body) => ({
        url: "api/user/updateSection",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteSection: builder.mutation({
      query: (body) => ({
        url: "api/user/deactivateSection",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllSectionMutation,
  useCreateSectionMutation,
  useUpdateSectionMutation,
  useDeleteSectionMutation,
} = sectionApi;
