import { createSlice } from "@reduxjs/toolkit";

const contentSlice = createSlice({
  name: "masterContentSeletedValue",
  initialState: [],
  reducers: {
    masterContentSeletedValue: (state, action) => {
      const existingIndex = state?.findIndex(
        (item) => item.type === action.payload.type
      );
      if (existingIndex !== -1) {
        state[existingIndex] = action.payload;
      } else {
        state.push(action.payload);
      }
    },
    previewContentData: (state, action) => {
      return action.payload;
      // Ensure the state is always an array
      // return Array.isArray(action.payload) ? action.payload : [action.payload];
    },
  },
});

export const { masterContentSeletedValue, previewContentData } =
  contentSlice.actions;
export default contentSlice.reducer;
