export const formatDateTime = (dateTimeString) => {
  const options = {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };
  return new Date(dateTimeString).toLocaleDateString(undefined, options);
};

export const semester = [
  { _id: "semesterOne", semesterName: "Semester One" },
  { _id: "semesterTwo", semesterName: "Semester Two" },
];

export const sorterDate = (a, b) => {
  const dateA = new Date(a);
  const dateB = new Date(b);
  return dateB - dateA;
};

export const validationCharacters = (rule, value, callback, message) => {
  const nameRegex = /^(?!\s+$)[a-zA-Z\s]{2,}$/;
  const fieldName = rule?.field.replace(/name/gi, "");
  const field = fieldName.replace(/^\w/, (c) => c.toUpperCase());
  if (!value || !value.match(nameRegex)) {
    callback(
      message ||
        `Please enter a valid ${field} Name with at least 2 characters.`
    );
  } else {
    callback();
  }
};

export const validationCharactersNumber = (rule, value, callback) => {
  const nameRegex = /^(?!\s+$)[\u0900-\u097Fa-zA-Z0-9\s\.\-\:]{2,}$/;
  const fieldName = rule?.field.replace(/name/gi, "");
  const field = fieldName.replace(/^\w/, (c) => c.toUpperCase());
  if (!value || !value.match(nameRegex)) {
    callback(`Please enter a valid ${field} Name with at least 2 characters.`);
  } else {
    callback();
  }
};

export const validationCharactersAlphabets = (
  rule,
  value,
  callback,
  message
) => {
  const nameRegex = /^(?!\s+$).{2,}$/;
  const fieldName = rule?.field.replace(/name/gi, "");
  const formattedFieldName = fieldName.replace(/^\w/, (c) => c.toUpperCase());

  if (!value || !value.match(nameRegex)) {
    callback(
      message ||
        `Please enter a valid ${formattedFieldName} with at least 2 characters.`
    );
  } else {
    callback();
  }
};
export const validationCharactersAlphabetsForOptions = (
  rule,
  value,
  callback,
  message
) => {
  const nameRegex = /^(?!\s+$).{1,}$/;
  const fieldName = rule?.field.replace(/name/gi, "");
  const formattedFieldName = fieldName.replace(/^\w/, (c) => c.toUpperCase());

  if (!value || !value.match(nameRegex)) {
    callback(
      message ||
        `Please enter a valid ${formattedFieldName} with at least 1 characters.`
    );
  } else {
    callback();
  }
};

export const validationOnlyNumbers = (rule, value, callback, message) => {
  const numberRegex = /^[0-9]+$/;

  const fieldName = rule?.field.replace(/name/gi, "");
  const formattedFieldName = fieldName.replace(/^\w/, (c) => c.toUpperCase());

  const valueAsString = String(value);

  if (valueAsString === undefined || !valueAsString.match(numberRegex)) {
    callback(
      message ||
        `Please enter a valid ${formattedFieldName} with at least 2 characters.`
    );
  } else {
    callback();
  }
};

// new validationonlynumbers
// export const validationOnlyNumbers = (rule, value, callback, message) => {
//   const numberRegex = /^[0-9]+$/;

//   const fieldName = rule?.field.replace(/name/gi, "");
//   const formattedFieldName = fieldName.replace(/^\w/, (c) => c.toUpperCase());

//   if (value === undefined || value === null || !String(value).match(numberRegex)) {
//     callback(
//       message ||
//         `Please enter a valid ${formattedFieldName} with at least 2 characters.`
//     );
//   } else {
//     callback();
//   }
// };

export const validationLink = (rule, value, callback, message) => {
  const urlRegex = /^https?:\/\/(www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+(\S*)$/;

  const fieldName = rule?.field.replace(/name/gi, "");
  const formattedFieldName = fieldName.replace(/^\w/, (c) => c.toUpperCase());

  if (!value || !value.match(urlRegex)) {
    callback(
      message ||
        `Please enter a valid ${formattedFieldName} with at least 2 characters.`
    );
  } else {
    callback();
  }
};
