import React from "react";
import PropTypes from "prop-types";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { Popover, Button } from "antd";
import { useSelector } from "react-redux";

const UserMenu = ({ onLogout }) => {
  const user = useSelector((state) => state.userDetails);

  const content = (
    <div>
      <h3>{user.fullname}</h3>
      <Button type="text" icon={<LogoutOutlined />} onClick={onLogout}>
        Logout
      </Button>
    </div>
  );

  return (
    <Popover content={content} trigger="click">
      <Button type="text" icon={<UserOutlined />} />
    </Popover>
  );
};

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
};

export default UserMenu;
