import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";

const UserModal = ({ title, description, visible, onCancel, onConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={onCancel}
      centered
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="confirm" onClick={handleConfirm}>
          Confirm
        </Button>,
      ]}
    >
      <p>{description}</p>
    </Modal>
  );
};

UserModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default UserModal;
