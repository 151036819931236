/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Form, Col, Row, Select, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { masterSeletedValue } from "../../common/features/masterSlice";
import Subject from "../Subject";
import Chapter from "../Chapter";
import Section from "../Section";
import { FormAntd, PageHeader, StyledForm } from "./Master.styles";
import PermissionChecker from "../../components/PermissionChecker";
import { useGetAllMasterMutation } from "../../common/services/schools";
import { getAllMaster } from "../../common/features/schoolSlice";

const Master = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [form] = Form.useForm();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [getMasterDetails, { data: getMaster }] = useGetAllMasterMutation();
   const [selectedMediumId, setSelectedMediumId] = useState(null);
   const [filteredStandards, setFilteredStandards] = useState([]);

  const user = useSelector((state) => state.userDetails);
  const data = useSelector((state) => state.getAllMaster);

  // console.log(data, "data check");

  useEffect(() => {
    if (user?.school) {
      const schoolById = {
        schoolId: user?.school?._id,
      };
      getMasterDetails(schoolById);
    }
  }, [user]);

  useEffect(() => {
    if (user?.school) {
      form.setFieldsValue({
        school: user.school._id,
      });
    }
    if (user?.school && user?.school?.board.length > 0) {
      form.setFieldsValue({
        board: user.school.board[0]._id,
      });
      handleSelectionChange("school", user.school._id);
      handleSelectionChange(
        "board",
        user.school.board.length > 0 ? user.school.board[0]._id : ""
      );
    }
  }, [user, form]);

  useEffect(() => {
    dispatch(getAllMaster(getMaster));
  }, [getMaster,dispatch]);

   useEffect(() => {
    if (selectedMediumId && data?.standards) {
      const filtered = data.standards.filter(
        (item) => item?.medium === selectedMediumId
      );
      setFilteredStandards(filtered);
      console.log(filtered, "filteredStandards data check");
    }
  }, [selectedMediumId, data]);

  const handleSelectionChange = (type, value) => {
    const newSelection = { type, value };

    if (type === "medium") {
      setSelectedMediumId(value);
    }
    
    console.log(newSelection, "newSelection data check");
    dispatch(masterSeletedValue(newSelection));
  };

  const onFinish = () => {
    setFormSubmitted(true);
  };

  console.log(selectedMediumId, "selectedMediumId data check");
  // const filteredStandards = data?.standards?.filter(
  //   (item) => item?.mediumId === selectedMediumId
  // );

  // console.log(filteredStandards, "filteredStandards data check");


  return (
    <>
      <PageHeader>
        <h2>Master</h2>
      </PageHeader>
      <FormAntd form={form} layout="vertical" onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item
              name="board"
              label="Board"
              rules={[{ required: true, message: "Please select your board" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a board"
                  onChange={(value) => handleSelectionChange("board", value)}
                  value={user.school._id}
                  disabled
                >
                  {user?.school?.board.map((item) => (
                    <Option key={item?._id} value={item?._id}>
                      {item?.boardName}
                    </Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="school"
              label="School"
              rules={[{ required: true, message: "Please select your school" }]}
            >
              {user?.school && (
                <Select
                  placeholder="Select a School"
                  onChange={(value) => handleSelectionChange("school", value)}
                  value={user.school._id}
                  disabled
                >
                  <Option value={user.school._id} key={user.school._id}>
                    {user.school.schoolName}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="medium"
              label="Medium"
              rules={[{ required: true, message: "Please select your medium" }]}
            >
              <Select
                placeholder="Select a Medium"
                onChange={(value) => handleSelectionChange("medium", value)}
              >
                {data?.mediums?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.mediumName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="standard"
              label="Standard"
              rules={[
                { required: true, message: "Please select your standard" },
              ]}
            >
              <Select
                placeholder="Select a Standard"
                onChange={(value) => handleSelectionChange("standard", value)}
              >
                {filteredStandards?.map((item) => (
                  <Option value={item._id} key={item._id}>
                    {item.standardName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <PermissionChecker resource="master" action="create">
            <Button
              style={{ backgroundColor: "#1f292b" }}
              type="primary"
              htmlType="submit"
            >
              Apply
            </Button>
          </PermissionChecker>
        </Form.Item>
      </FormAntd>
      {formSubmitted && (
        <>
          <StyledForm>
            <Subject />
          </StyledForm>
          <StyledForm>
            <Chapter />
          </StyledForm>
          <StyledForm>
            <Section />
          </StyledForm>
        </>
      )}
    </>
  );
};
export default Master;
