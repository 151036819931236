import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import { useDeleteBoardMutation, useGetAllBoardMutation } from "../../common/services/board";
import { BoardLogo, PageHeader } from "./Board.styles";
import { Button, Input } from "antd";
import UserModal from "../../components/Modal";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const Board = () => {
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("")

  const [getAllBoard, { data, isLoading }] = useGetAllBoardMutation();
  const [deleteBoard] = useDeleteBoardMutation();

  useEffect(() => {
    getAllBoard();
  }, [getAllBoard]);

  const columns = [
    {
      title: "Board Logo",
      dataIndex: "boardLogo",
      key: "boardLogo",
      render: (logo) => <>{logo ? <BoardLogo src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`} alt="Subject Logo" /> : '-'}</>,
    },
    {
      title: "Board Name",
      dataIndex: "boardName",
      key: "boardName",
    },
    {
      title: "Board ID",
      dataIndex: "boardId",
      key: "boardId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const handleCreateBoardClick = () => {
    navigate(`/board/create`);
  };

  const handleEdit = (board) => {
    navigate(`/board/edit/${board._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const boardId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteBoard(boardId);
      if (response.data) {
        toast.success(response.data.message)
      }
      if (response.error) {
        toast.error(response.error.data.message)
      }
      getAllBoard();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "board", action: "update" }
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "board", action: "delete" }
    },
  ];

  const filteredBoards = data?.boards.filter(
    (board) =>
      board.boardName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      board.boardId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      board.description.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Board List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="board" action="create">
          <Button onClick={handleCreateBoardClick}>
            Create Board
          </Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={filteredBoards && [...filteredBoards].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Board"}
        description={"Are you sure you want to delete this board?"}
      />
    </>
  );
};

export default Board;
