import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, Col, Row, Upload, Button } from "antd";
import { useGetIdByBoardMutation, useUpdateBoardMutation } from "../../common/services/board";
import { StyledForm } from "./Board.styles";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import {  validationCharactersNumber } from "../../constants";

const EditBoard = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [updateBoardMutation] = useUpdateBoardMutation();
    const [getBoardMutation, { data: boardData }] = useGetIdByBoardMutation();

    useEffect(() => {
        const boardById = {
            id: id,
        };
        getBoardMutation(boardById)
    }, [id])

    useEffect(() => {
        if (boardData && boardData.boards) {
            const { boardName, description, boardLogo } =
                boardData.boards[0];

            form.setFieldsValue({
                boardName: boardName || "",
                description: description || "",
                boardLogo: boardLogo
                    ? [{
                        uid: '-1',
                        name: boardLogo.path,
                        status: 'done',
                        url: `https://ilearnbackend.s3.ap-south-1.amazonaws.com/${boardLogo.path}`
                    }]
                    : [],
            });
        }
    }, [boardData, form]);

    const onFinish = async (values) => {
        const { boardName, description, boardLogo } = values;
        const formData = new FormData();
        formData.append("id", id);
        formData.append("boardName", boardName);
        formData.append("description", description);
        if (boardLogo && boardLogo[0] && boardLogo[0].originFileObj) {
            formData.append("boardLogo", boardLogo[0].originFileObj);
        }

        try {
            const response = await updateBoardMutation(formData);
            if (response.data) {
                toast.success(response.data.message)
            }
            if (response.error) {
                toast.error(response.error.data.message)
            }
            navigate(`/board`);
        } catch (error) {
            console.error("Update board error:", error);
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <>
            <h2>Edit Board</h2>
            <StyledForm form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            name="boardName"
                            label="Board Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your board name",
                                },
                                { validator: validationCharactersNumber },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name="boardLogo"
                            label="Board Logo"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                name="logo"
                                listType="picture"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update
                            </Button>
                            <Button onClick={() => navigate("/board")} style={{ marginLeft: 8 }}>
                                Cancel
                            </Button>
                            <Button onClick={() => navigate("/board")} style={{ marginLeft: 8 }}>
                                Back
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </StyledForm>
        </>
    );
};

export default EditBoard;
