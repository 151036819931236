import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "userDetails",
  initialState: [],
  reducers: {
    userDetails: (state, action) => {
      return action.payload?.User
    },
  },
});

export const { userDetails } = usersSlice.actions;
export default usersSlice.reducer;