import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UserModal from "../../components/Modal";
import {
  useDeleteSchoolMutation,
  useGetAllSchoolsMutation,
} from "../../common/services/schools";
import { PageHeader, SchoolLogo } from "./Schools.styles";
import { Button, Input } from "antd";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const Schools = () => {
  const navigate = useNavigate();

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteUserData, setTempDeleteUserData] = useState();
  const [searchQuery, setSearchQuery] = useState("");

  const [getAllSchools, { data, isLoading, error }] =
    useGetAllSchoolsMutation();
  const [deleteSchool] = useDeleteSchoolMutation();

  // console.log(error, "error in school check");

  useEffect(() => {
    getAllSchools();
  }, [getAllSchools]);

  if (error) {
    toast.error(error?.message);
  }

  const columns = [
    {
      title: "School Logo",
      dataIndex: "schoolLogo",
      key: "schoolLogo",

      render: (logo) => (
        <>
          {logo ? (
            <SchoolLogo
              src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`}
              alt="Subject Logo"
            />
          ) : (
            "-"
          )}
        </>
      ),
    },
    {
      title: "School Name",
      dataIndex: "schoolName",
      key: "schoolName",
    },
    {
      title: "School ID",
      dataIndex: "schoolId",
      key: "schoolId",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
  ];

  const handleCreateSchoolClick = () => {
    navigate(`/school/create`);
  };

  const handleEdit = (school) => {
    navigate(`/school/edit/${school._id}`);
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteUserData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const schoolId = {
      id: tempDeleteUserData._id,
    };

    try {
      const response = await deleteSchool(schoolId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      getAllSchools();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "school", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "school", action: "delete" },
    },
  ];

  const filteredSchools = data?.schools.filter(
    (school) =>
      school.schoolName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.schoolId.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.address.toLowerCase().includes(searchQuery.toLowerCase()) ||
      school.city.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>School List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="school" action="create">
          <Button onClick={handleCreateSchoolClick}>Create School</Button>
        </PermissionChecker>
      </PageHeader>
      <DataTable
        dataSource={
          filteredSchools &&
          [...filteredSchools].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete School"}
        description={"Are you sure you want to delete this school?"}
      />
    </>
  );
};

export default Schools;
