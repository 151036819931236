import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const contentApi = createApi({
  reducerPath: "contentApi",
  baseQuery: fetchBaseQuery({
    // baseUrl: `http://localhost:8002/`,
    baseUrl: `${process.env.REACT_APP_BASE_URL}/v1/contentservice`,
    validateStatus: (response) => {
      if (response.status === 401) {
        window.location.href = "/login";
        return false;
      } else if (response.status >= 200 && response.status < 300) {
        return true;
      } else {
        throw new Error(`HTTP error ${response.status}`);
      }
    },
  }),
  endpoints: (builder) => ({
    getAllContent: builder.mutation({
      query: (body) => ({
        url: "api/content/getMediaContent",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    createContent: builder.mutation({
      query: (body) => ({
        url: "api/content/insertMediaContent",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    updateContent: builder.mutation({
      query: (body) => ({
        url: "api/content/updateMediaContent",
        method: "PUT",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteContent: builder.mutation({
      query: (body) => ({
        url: "api/content/deactivateMediaContent",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
    deleteContentSection: builder.mutation({
      query: (body) => ({
        url: "api/content/deleteContent",
        method: "POST",
        body: body,
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }),
    }),
  }),
});

export const {
  useGetAllContentMutation,
  useCreateContentMutation,
  useUpdateContentMutation,
  useDeleteContentMutation,
  useDeleteContentSectionMutation,
} = contentApi;
