import React from "react";
import PropTypes from "prop-types";
import { ActionButton, ActionContainer } from "./DataTable.styles";
import { Table } from "antd";
import { useSelector } from "react-redux";

const DataTable = ({ dataSource, columns, actions, loading }) => {
  const permissions = useSelector((state) => state.permissionsList);

  // console.log(dataSource, "data check for data table key");
  const hasPermission = (resource, action) => {
    return (
      permissions && permissions[resource] && permissions[resource][action]
    );
  };

  const renderActions = (record) => {
    const allowedActions = actions?.filter((action) =>
      hasPermission(action?.permission?.resource, action?.permission?.action)
    );

    if (allowedActions.length === 0) return null;
    return (
      <ActionContainer>
        {allowedActions.map((action) => (
          <ActionButton
            key={action?.key}
            style={action?.style}
            type="text"
            icon={action?.icon}
            onClick={() => action?.onClick(record)}
          >
            {action?.label}
          </ActionButton>
        ))}
      </ActionContainer>
    );
  };

  const columnsWithActions =
    actions?.length > 0 && renderActions() !== null
      ? [
          ...columns,
          {
            title: "Action",
            key: "action",
            render: (_, record) => renderActions(record),
          },
        ]
      : [...columns];

  return (
    <Table
      loading={loading}
      bordered
      dataSource={dataSource}
      key={dataSource?._id}
      columns={columnsWithActions}
      pagination={{ pageSize: 5 }}
      scroll={{
        x: 1300,
      }}
    />
  );
};

DataTable.propTypes = {
  dataSource: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.node.isRequired,
      onClick: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
      permission: PropTypes.shape({
        resource: PropTypes.string.isRequired,
        action: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  loading: PropTypes.bool,
};

export default DataTable;
