import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Switch } from "antd";
import {
  PermissionList,
  PermissionName,
  StyledCard,
  PermisionLabel,
  StyledForm,
} from "./Roles.styles";
import { useCreateRoleMutation } from "../../common/services/roles";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { ArrowLeftOutlined } from "@ant-design/icons";

const RolePage = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const initialRole = useSelector((state) => state.intialRoleList);

  const [createRole] = useCreateRoleMutation();

  const [roleName, setRoleName] = useState("");
  const [permissions, setPermissions] = useState(initialRole?.permissions);

  console.log(permissions, "permissions");

  const handlePermissionToggle = (category, action) => {
    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [action]: !prevPermissions[category][action],
      },
    }));
  };

  const handleSaveRole = async () => {
    if (roleName) {
      const roleData = {
        name: roleName,
        permissions: { ...permissions },
      };

      try {
        const response = await createRole(roleData);
        if (response.data) {
          toast.success(response.data.message);
        }
        if (response.error) {
          toast.error(response.error.data.message);
        }
        navigate(`/role`);
      } catch (error) {
        console.error("Login error:", error);
      }
    }
  };

  return (
    <div>
      {/* <Button size="small" onClick={() => navigate("/role")}> */}
      <ArrowLeftOutlined
        className="cursor-pointer"
        onClick={() => navigate("/role")}
      />
      {/* </Button> */}
      <h1>Create Role</h1>
      <StyledForm form={form}>
        <Row>
          <Form.Item
            name="roleName"
            label="Role Name"
            rules={[{ required: true, message: "Please enter your role name" }]}
          >
            <Input onChange={(e) => setRoleName(e.target.value)} />
          </Form.Item>
        </Row>
        <br />
        {Object.keys(permissions).map((category) => (
          <StyledCard key={category} title={category}>
            <PermissionList>
              {Object.keys(permissions[category]).map((action) => (
                <div key={action}>
                  <PermissionName>
                    <PermisionLabel>
                      {action.charAt(0).toUpperCase() + action.slice(1)}:
                    </PermisionLabel>
                    <Switch
                      checked={permissions[category][action]}
                      onChange={() => handlePermissionToggle(category, action)}
                    />
                  </PermissionName>
                </div>
              ))}
            </PermissionList>
          </StyledCard>
        ))}
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={handleSaveRole}>
                Create
              </Button>
              <Button
                onClick={() => navigate("/role")}
                style={{ marginLeft: 8 }}
              >
                Cancel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </StyledForm>
    </div>
  );
};

export default RolePage;
