import { createSlice } from "@reduxjs/toolkit";

const quizSlice = createSlice({
    name: "quizTempDetails",
    initialState: [],
    reducers: {
        quizTempDetails: (state, action) => {
            return action.payload
        },
    },
});

export const { quizTempDetails } = quizSlice.actions;
export default quizSlice.reducer;
