import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { DeleteFilled, EditFilled } from "@ant-design/icons";
import UserModal from "../../components/Modal";
import { PageHeader, BannerImg } from "./Banner.styles";
import { Button, Input } from "antd";
import { useDeleteBannerMutation, useGetBannersMutation } from "../../common/services/banner";
import { useDispatch } from "react-redux";
import { bannerData } from "../../common/features/bannerSlice";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const Banner = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [tempDeleteData, setTempDeleteData] = useState();
    const [searchQuery, setSearchQuery] = useState("");

    const [getAllBanner, { data, isLoading }] = useGetBannersMutation();
    const [deleteBanner] = useDeleteBannerMutation();

    useEffect(() => {
        getAllBanner();
    }, [getAllBanner]);

    const columns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",

        },
        {
            title: "Banner",
            dataIndex: "imageUrl",
            key: "imageUrl",

            render: (logo) => <><BannerImg src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`} alt="BannerImg" /></>,
        }
    ];

    const handleCreateSchoolClick = () => {
        navigate(`/banner/upload`);
    };

    const handleEdit = (banner) => {
        dispatch(bannerData(banner));
        navigate(`/banner/update/${banner._id}`);
    };

    const handleDelete = (user) => {
        setDeleteModalVisible(true);
        setTempDeleteData(user);
    };

    const handleCancelDelete = () => {
        setDeleteModalVisible(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteModalVisible(false);
        const schoolId = {
            id: tempDeleteData._id,
        };

        try {
            const response = await deleteBanner(schoolId);
            if (response.data) {
                toast.success(response.data.message)
            }
            if (response.error) {
                toast.error(response.error.data.message)
            }
            getAllBanner();
        } catch (error) {
            console.error("Login error:", error);
        }
    };

    const actions = [
        {
            key: "edit",
            icon: <EditFilled />,
            onClick: handleEdit,
            label: "Edit",
            style: { backgroundColor: "#7c18ff" },
            permission: { resource: "cms", action: "update" }
        },
        {
            key: "delete",
            icon: <DeleteFilled />,
            onClick: handleDelete,
            label: "Delete",
            style: { backgroundColor: "#ff1818" },
            permission: { resource: "cms", action: "delete" }
        },
    ];

    const filteredBanners = data?.banners.filter(banner =>
        banner.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <>
            <h2 style={{ paddingLeft: "5px" }}>Banner List</h2>
            <PageHeader>
                <Input
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    style={{ maxWidth: "200px" }}
                />
                <PermissionChecker resource="cms" action="create">
                    <Button onClick={handleCreateSchoolClick}>
                        Upload Banner
                    </Button>
                </PermissionChecker>
            </PageHeader>
            <DataTable
                dataSource={filteredBanners && [...filteredBanners].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
                columns={columns}
                actions={actions}
                loading={isLoading}
            />
            <UserModal
                visible={deleteModalVisible}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title={"Delete Banner"}
                description={"Are you sure you want to delete this banner?"}
            />
        </>
    );
};

export default Banner;
