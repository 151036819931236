import { createSlice } from "@reduxjs/toolkit";

const roleSlice = createSlice({
    name: "intialRoleList",
    initialState: [],
    reducers: {
        intialRoleList: (state, action) => {
            return action.payload
        },
    },
});

export const { intialRoleList } = roleSlice.actions;
export default roleSlice.reducer;
