import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { PageHeader } from "./Test.styles";
import { Row, Col, Button, Drawer, Input, Select } from "antd";
import {
  useDeleteTestMutation,
  useGetAllTestMutation,
} from "../../common/services/test";
import { DeleteFilled, EditFilled, EyeOutlined } from "@ant-design/icons";
import UserModal from "../../components/Modal";
import { testTempDetails } from "../../common/features/testSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PermissionChecker from "../../components/PermissionChecker";

const { Option } = Select;

const Test = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.userDetails);
  const getMaster = useSelector((state) => state.getAllMaster);

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [tempQuestionView, setTempQuestionView] = useState([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [tempDeleteData, setTempDeleteData] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const [filters, setFilters] = useState({
    schoolId: user?.school?._id,
  });

  const [filteredStandards, setFilteredStandards] = useState(
    getMaster?.standards
  );
  const [filteredSubjects, setFilteredSubjects] = useState(getMaster?.subjects);
  const [filteredChapters, setFilteredChapters] = useState(getMaster?.chapters);
  const [filteredSections, setFilteredSections] = useState(getMaster?.sections);

  const [getAllTest, { data, isLoading }] = useGetAllTestMutation();
  const [deleteTest] = useDeleteTestMutation();

  // console.log(user, "test user check");

  useEffect(() => {
    if (user.school) {
      handleCallGetAPI();
    }
  }, [user, filters]);

  const handleCallGetAPI = () => {
    getAllTest(filters);
  };

  const handleFilterChange = (value, key) => {
    // setFilters({
    //   [key]: value,
    // });

    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));

    // test purpose for filters
    if (key === "mediumId") {
      const standards = getMaster.standards.filter(
        (standard) => standard.medium === value
      );
      setFilteredStandards(standards);
      setFilteredSubjects([]);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "standardId") {
      const subjects = getMaster.subjects.filter(
        (subject) => subject.standard === value
      );
      setFilteredSubjects(subjects);
      setFilteredChapters([]);
      setFilteredSections([]);
    } else if (key === "subjectId") {
      const chapters = getMaster.chapters.filter(
        (chapter) => chapter.subject === value
      );
      setFilteredChapters(chapters);
      setFilteredSections([]);
    } else if (key === "chapterId") {
      const sections = getMaster.sections.filter(
        (section) => section.chapter === value
      );
      setFilteredSections(sections);
    }
  };

  const handleClearFilters = () => {
    setFilters({
      schoolId: user?.school?._id,
    });

    setFilteredStandards(getMaster?.standards);
    setFilteredSubjects(getMaster?.subjects);
    setFilteredChapters(getMaster?.chapters);
    setFilteredSections(getMaster?.sections);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Test Type",
      dataIndex: "testType",
      key: "testType",
    },
    {
      title: "Total Questions",
      dataIndex: "totalQuestions",
      key: "totalQuestions",
    },
    {
      title: "Total Marks",
      dataIndex: "totalMarks",
      key: "totalMarks",
    },
    {
      title: "Medium",
      dataIndex: "medium",
      key: "medium",
      render: (medium) => <>{medium?.mediumName}</>,
    },
    {
      title: "Standard",
      dataIndex: "standard",
      key: "standard",
      render: (standard) => <>{standard?.standardName}</>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (subject) => <>{subject?.subjectName}</>,
    },
    {
      title: "Chapter",
      dataIndex: "chapter",
      key: "chapter",
      render: (chapter) => <>{chapter?.chapterName}</>,
    },
  ];

  const questionColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Question",
      dataIndex: "question",
      key: "question",
      render: (question) => question?.question,
    },
    {
      title: "Option",
      dataIndex: "question",
      key: "option",
      render: (question) => (
        <ul>
          {question?.options?.map((item, index) => (
            <li key={index}>{item?.option}</li>
          ))}
        </ul>
      ),
    },
    {
      title: "Mark",
      dataIndex: "mark",
      key: "mark",
    },
  ];

  const handleClickViewQuestions = (value) => {
    setTempQuestionView(value?.questions);
    setDrawerVisible(true);
  };

  const handleCreateQuestionClick = () => {
    navigate(`/test/create`);
  };

  const handleEdit = (test) => {
    navigate(`/test/edit/${test._id}`);
    dispatch(testTempDetails(test));
  };

  const handleDelete = (user) => {
    setDeleteModalVisible(true);
    setTempDeleteData(user);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleConfirmDelete = async () => {
    setDeleteModalVisible(false);
    const testId = {
      id: tempDeleteData._id,
    };

    try {
      const response = await deleteTest(testId);
      if (response.data) {
        toast.success(response.data.message);
      }
      if (response.error) {
        toast.error(response.error.data.message);
      }
      handleCallGetAPI();
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  const actions = [
    {
      key: "view",
      icon: <EyeOutlined />,
      onClick: handleClickViewQuestions,
      label: "View Questions",
      style: { backgroundColor: "#313131" },
    },
    {
      key: "edit",
      icon: <EditFilled />,
      onClick: handleEdit,
      label: "Edit",
      style: { backgroundColor: "#7c18ff" },
      permission: { resource: "content", action: "update" },
    },
    {
      key: "delete",
      icon: <DeleteFilled />,
      onClick: handleDelete,
      label: "Delete",
      style: { backgroundColor: "#ff1818" },
      permission: { resource: "content", action: "delete" },
    },
  ];

  const filteredTests = data?.tests.filter(
    (test) =>
      test.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      test.testType.toLowerCase().includes(searchQuery.toLowerCase()) ||
      test.standard.standardName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      test.subject.subjectName
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      test.chapter.chapterName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <h2 style={{ paddingLeft: "5px" }}>Test List</h2>
      <PageHeader>
        <Input
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ maxWidth: "200px" }}
        />
        <PermissionChecker resource="content" action="create">
          <Button onClick={handleCreateQuestionClick}>Create Test</Button>
        </PermissionChecker>
      </PageHeader>
      <div style={{ marginBottom: 10, padding: "0 5px" }}>
        <Row gutter={24}>
          <Col span={4}>
            <Select
              placeholder="Medium"
              value={filters?.mediumId}
              onChange={(value) => handleFilterChange(value, "mediumId")}
              style={{ width: 150, display: "inline" }}
            >
              {getMaster?.mediums?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.mediumName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Standard"
              value={filters?.standardId}
              onChange={(value) => handleFilterChange(value, "standardId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredStandards?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.standardName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Subject"
              value={filters?.subjectId}
              onChange={(value) => handleFilterChange(value, "subjectId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSubjects?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.subjectName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Chapter"
              value={filters?.chapterId}
              onChange={(value) => handleFilterChange(value, "chapterId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredChapters?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.chapterName}
                </Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select
              placeholder="Section"
              value={filters?.sectionId}
              onChange={(value) => handleFilterChange(value, "sectionId")}
              style={{ width: 150, display: "inline" }}
            >
              {filteredSections?.map((item) => (
                <Option value={item?._id} key={item?._id}>
                  {item?.sectionName}
                </Option>
              ))}
            </Select>
          </Col>
          <Button onClick={handleClearFilters} style={{ marginLeft: 10 }}>
            Clear Filters
          </Button>
        </Row>
      </div>
      <DataTable
        dataSource={
          filteredTests &&
          [...filteredTests].sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )
        }
        columns={columns}
        actions={actions}
        loading={isLoading}
      />
      <Drawer
        title="Test List"
        placement="right"
        closable={true}
        visible={drawerVisible}
        onClose={() => setDrawerVisible(false)}
        width={700}
      >
        <DataTable
          columns={questionColumns}
          dataSource={
            tempQuestionView &&
            [...tempQuestionView].sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            )
          }
          loading={false}
        />
      </Drawer>
      <UserModal
        visible={deleteModalVisible}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
        title={"Delete Test"}
        description={"Are you sure you want to delete this test?"}
      />
    </>
  );
};

export default Test;
