import { createSlice } from "@reduxjs/toolkit";

const schoolSlice = createSlice({
    name: "schoolDetails",
    initialState: [],
    reducers: {
        getAllMaster: (state, action) => {
            return action.payload
        },
    },
});

export const { getAllMaster } = schoolSlice.actions;
export default schoolSlice.reducer;
