import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./components/Dashboard";
import UserList from "./pages/UserList";
import UserEdit from "./pages/UserList/EditUser";
import CreateUser from "./pages/UserList/CreateUser";
import Roles from "./pages/Roles";
import CreateRole from "./pages/Roles/CreateRole";
import EditRole from "./pages/Roles/EditRole";
import Schools from "./pages/Schools";
import CreateSchool from "./pages/Schools/CreateSchool";
import Board from "./pages/Board";
import Medium from "./pages/Medium";
import Standard from "./pages/Standard";
import CreateBoard from "./pages/Board/CreateBoard";
import CreateMedium from "./pages/Medium/CreateMedium";
import CreateStandard from "./pages/Standard/CreateStandard";
import Master from "./pages/Master";
import Content from "./pages/Content";
import CreateContent from "./pages/Content/CreateContent";
import Question from "./pages/Question";
import PreviewContent from "./pages/Content/PreviewContent";
import CreateQuestion from "./pages/Question/CreateQuestion";
import EditSchool from "./pages/Schools/EditSchool";
import Quiz from "./pages/Quiz";
import CreateQuiz from "./pages/Quiz/CreateQuiz";
import Test from "./pages/Test";
import CreateTest from "./pages/Test/CreateTest";
import Banner from "./pages/Banner";
import UploadBanner from "./pages/Banner/CreateBanner";
import UpdateBanner from "./pages/Banner/UpdateBanner";
import EditBoard from "./pages/Board/EditBoard";
import EditMedium from "./pages/Medium/EditMedium";
import EditStandard from "./pages/Standard/EditStandard";
import EditContent from "./pages/Content/EditContent";
import EditQuestion from "./pages/Question/EditQuestion";
import EditTest from "./pages/Test/EditTest";
import EditQuiz from "./pages/Quiz/EditQuiz";
import Student from "./pages/Student";
import CreateStudent from "./pages/Student/CreateStudent";
import EditStudent from "./pages/Student/EditStudent";
import Notification from "./pages/Notification";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/user" element={<UserList />} />
            <Route path="/user/create" element={<CreateUser />} />
            <Route path="/user/edit/:id" element={<UserEdit />} />
            <Route path="/student" element={<Student />} />
            <Route path="/student/edit/:id" element={<EditStudent />} />
            <Route path="/student/create" element={<CreateStudent />} />
            <Route path="/role" element={<Roles />} />
            <Route path="/role/create" element={<CreateRole />} />
            <Route path="/role/edit/:roleId" element={<EditRole />} />
            <Route path="/school" element={<Schools />} />
            <Route path="/school/create" element={<CreateSchool />} />
            <Route path="/school/edit/:id" element={<EditSchool />} />
            <Route path="/board" element={<Board />} />
            <Route path="/board/create" element={<CreateBoard />} />
            <Route path="/board/edit/:id" element={<EditBoard />} />
            <Route path="/medium" element={<Medium />} />
            <Route path="/medium/create" element={<CreateMedium />} />
            <Route path="/medium/edit/:id" element={<EditMedium />} />
            <Route path="/standard" element={<Standard />} />
            <Route path="/standard/create" element={<CreateStandard />} />
            <Route path="/standard/edit/:id" element={<EditStandard />} />
            <Route path="/master" element={<Master />} />
            <Route path="/content" element={<Content />} />
            <Route path="/content/create" element={<CreateContent />} />
            <Route path="/content/preview" element={<PreviewContent />} />
            <Route path="/content/edit/:id" element={<EditContent />} />
            <Route path="/question" element={<Question />} />
            <Route path="/question/create" element={<CreateQuestion />} />
            <Route path="/question/edit/:id" element={<EditQuestion />} />
            <Route path="/quiz" element={<Quiz />} />
            <Route path="/quiz/create" element={<CreateQuiz />} />
            <Route path="/quiz/edit/:id" element={<EditQuiz />} />
            <Route path="/test" element={<Test />} />
            <Route path="/test/create" element={<CreateTest />} />
            <Route path="/test/edit/:id" element={<EditTest />} />
            <Route path="/banner" element={<Banner />} />
            <Route path="/banner/upload" element={<UploadBanner />} />
            <Route path="/banner/update/:id" element={<UpdateBanner />} />
            <Route path="/notification" element={<Notification />} />
          </Route>
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
