import React, { useEffect, useState } from "react";
import { Form, Input, Col, Row, Select, Button, Upload, Spin } from "antd";
import { useCreateChapterMutation, useDeleteChapterMutation, useGetAllChapterMutation, useUpdateChapterMutation } from "../../common/services/chapter";
import { useDispatch, useSelector } from "react-redux";
import { DeleteFilled, EditFilled, UploadOutlined } from "@ant-design/icons";
import DataTable from "../../components/DataTable";
import { semester, validationCharactersAlphabets } from "../../constants";
import { toast } from "react-toastify";
import UserModal from "../../components/Modal";
import { chaptersList } from "../../common/features/chapterSlice";
import PermissionChecker from "../../components/PermissionChecker";

const Chapter = () => {
    const dispatch = useDispatch();
    const { Option } = Select;
    const [form] = Form.useForm();
    const [filteredValue, setFilteredValue] = useState()
    const [editChapter, setEditChapter] = useState(null);
    const [updateTempChapter, setUpdateTempChapter] = useState()
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [tempDeleteData, setTempDeleteData] = useState();
    const [filterSubject, setFilterSubject] = useState()
    const [filterCurrentSubject, setFilterCurrentSubject] = useState()

    const user = useSelector((state) => state.userDetails)
    const masterSeletedValue = useSelector((state) => state.masterSeletedValue)
    const filteredSubject = useSelector((state) => state.filteredSubject)

    const [createChapterMute, { isLoading: createLoader }] = useCreateChapterMutation();
    const [updateChapterMute, { isLoading: updateLoader }] = useUpdateChapterMutation();
    const [deleteChapterMute] = useDeleteChapterMutation();
    const [getAllChapter, { data: chapterList, isLoading }] = useGetAllChapterMutation();

    // console.log(filterSubject, "filterSubject");

    // console.log(filteredValue, "filteredValue");

    useEffect(() => {
        handleGetChapterList()
    }, [user]);


    useEffect(() => {
        if (filteredValue) {
            const subjectRecords = filteredValue.filter((item) => item?.subject?._id === filterCurrentSubject);
            setFilterSubject(subjectRecords)
        }
    }, [filterCurrentSubject, filteredValue])

    const handleGetChapterList = () => {
        if (user.school) {
            const currentSchoolId = {
                schoolId: user.school._id,
            };
            getAllChapter(currentSchoolId);
        }
    }

    useEffect(() => {
        if (chapterList?.chapters) {
            const filteredChapters = chapterList.chapters.filter(chapter => {
                return masterSeletedValue.every(filter => {
                    return chapter[filter.type]?._id === filter.value;
                });
            });
            setFilteredValue(filteredChapters)
        }
        dispatch(chaptersList(chapterList?.chapters))
    }, [chapterList, masterSeletedValue])

    const handleSubjectChange = (id) => {
        setFilterCurrentSubject(id)
    }

    const handleEditChapter = (chapter) => {
        setEditChapter(true)
        setUpdateTempChapter(chapter)
        form.setFieldsValue({
            chapterName: chapter.chapterName,
            description: chapter.description,
            chapterImg: chapter.chapterImg
                ? [{
                    uid: '-1',
                    name: chapter.chapterImg.path,
                    status: 'done',
                    url: `https://ilearnbackend.s3.ap-south-1.amazonaws.com/${chapter.chapterImg.path}`
                }]
                : [],
        });
    }

    const handleDeleteChapter = (chapter) => {
        setDeleteModalVisible(true);
        setTempDeleteData(chapter);
    };

    const handleCancelDelete = () => {
        setDeleteModalVisible(false);
    };

    const handleConfirmDelete = async () => {
        setDeleteModalVisible(false);
        const formDataDelete = {
            "id": tempDeleteData._id
        }
        const response = await deleteChapterMute(formDataDelete);
        if (response.data) {
            toast.success(response.data.message)
        }
        if (response.error) {
            toast.error(response.error.data.message)
        }
        handleGetChapterList()
    };

    const handleCancelClick = () => {
        form.resetFields(['chapterName', 'description', 'chapterImg']);
        setEditChapter(false)
    }

    const onFinish = async (values) => {
        const { chapterName, description, subject, semester, chapterImg } = values;

        const formData = new FormData();
        formData.append('chapterName', chapterName);
        formData.append('description', description);
        formData.append('subject', subject);
        formData.append('semester', semester);
        if (chapterImg && chapterImg[0] && chapterImg[0].originFileObj) {
            formData.append("chapterImg", chapterImg[0].originFileObj);
        }

        masterSeletedValue.forEach(item => {
            switch (item.type) {
                case 'board':
                    formData.append('board', item.value);
                    break;
                case 'school':
                    formData.append('school', item.value);
                    break;
                case 'medium':
                    formData.append('medium', item.value);
                    break;
                case 'standard':
                    formData.append('standard', item.value);
                    break;
                default:
                    break;
            }
        });

        try {
            if (editChapter) {
                const formDataUpdate = new FormData();
                formDataUpdate.append('id', updateTempChapter._id);
                formDataUpdate.append('chapterName', chapterName);
                formDataUpdate.append('description', description);
                if (chapterImg && chapterImg[0] && chapterImg[0].originFileObj) {
                    formDataUpdate.append("chapterImg", chapterImg[0].originFileObj);
                }

                const response = await updateChapterMute(formDataUpdate);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields(['chapterName', 'description', 'chapterImg']);
                setEditChapter(false)
                handleGetChapterList()
            } else {
                const response = await createChapterMute(formData);
                if (response.data) {
                    toast.success(response.data.message)
                }
                if (response.error) {
                    toast.error(response.error.data.message)
                }
                form.resetFields(['chapterName', 'description', 'chapterImg']);
                handleGetChapterList()
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const columns = [
        {
            title: "Chapter Img",
            dataIndex: "chapterImg",
            key: "chapterImg",
            render: (logo) => <>{logo ? <img style={{ height: "30px" }} src={`https://ilearnbackend.s3.ap-south-1.amazonaws.com/${logo?.path}`} alt="Chapter Logo" /> : '-'}</>,
        },
        {
            title: "Chapter Name",
            dataIndex: "chapterName",
            key: "chapterName",
        },
        {
            title: "Medium",
            dataIndex: "medium",
            key: "medium",
            render: (medium) => <>{medium?.mediumName}</>,
        },
        
        {
            title: "Standard",
            dataIndex: "standard",
            key: "standard",
            render: (standard) => <>{standard?.standardName}</>,
        },
    ];

    const actions = [
        {
            key: "edit",
            icon: <EditFilled />,
            onClick: handleEditChapter,
            label: "",
            style: { backgroundColor: "#14828f" },
            permission: { resource: "master", action: "update" }
        },
        {
            key: "delete",
            icon: <DeleteFilled />,
            onClick: handleDeleteChapter,
            label: "",
            style: { backgroundColor: "#cb192f" },
            permission: { resource: "master", action: "delete" }
        },
    ];

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <>
            <h3>{editChapter ? "Update" : "Create"} Chapter</h3>
            <Spin spinning={createLoader || updateLoader} fullscreen />
            <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={24}>
                    {!editChapter &&
                        <>
                            <Col span={6}>
                                <Form.Item
                                    name="subject"
                                    label="Subject"
                                    rules={[{ required: true, message: "Please select your subject" }]}
                                >
                                    <Select placeholder="Select a Subject" onChange={handleSubjectChange}>
                                        {filteredSubject?.map((item) => (
                                            <Option value={item._id} key={item._id}>{item.subjectName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="semester"
                                    label="Semester"
                                    rules={[{ required: true, message: "Please select your semester" }]}
                                >
                                    <Select placeholder="Select a Semester">
                                        {semester?.map((item) => (
                                            <Option value={item._id} key={item._id}>{item.semesterName}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </>
                    }
                    <Col span={6}>
                        <Form.Item
                            name="chapterName"
                            label="Chapter Name"
                            required
                            rules={[
                                {
                                    required: false,
                                    message: "Please enter your chapter name",
                                },
                                { validator: validationCharactersAlphabets },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={6}>
                        <Form.Item
                            name="description"
                            label="Description"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name="chapterImg"
                            label="Chapter Img"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                        >
                            <Upload
                                accept="image/*"
                                name="logo"
                                listType="picture"
                                beforeUpload={() => false}
                                maxCount={1}
                            >
                                <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                        </Form.Item>
                    </Col>
                    <Col span={6} style={{ display: "flex", alignItems: 'flex-end' }}>
                        <Form.Item>
                            <PermissionChecker resource="master" action="create">
                                <Button htmlType="submit">{editChapter ? "Update" : "Add Chapter"}</Button>
                            </PermissionChecker>
                            {editChapter && <Button onClick={handleCancelClick} style={{ marginLeft: "10px" }} danger >Cancel</Button>}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            {filterSubject?.length > 0 &&
                <DataTable
                    dataSource={filterSubject && [...filterSubject].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))}
                    columns={columns}
                    actions={actions}
                    loading={isLoading}
                />
            }
            <UserModal
                visible={deleteModalVisible}
                onCancel={handleCancelDelete}
                onConfirm={handleConfirmDelete}
                title={"Delete Chapter"}
                description={"Are you sure you want to delete this chapter?"}
            />
        </>
    );
};

export default Chapter;
