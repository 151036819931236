import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLoginUserMutation } from "../../common/services/login";
import {
  StyledLoginWrapper,
  StyledLoginBox,
  StyledIllustrationWrapper,
  StyledIllustration,
  StyledLoginForm,
  StyledFormTitle,
  StyledFormItem,
  StyledInput,
  StyledPasswordInput,
  StyledButton,
} from "./Login.styles";
import { loginFailure, loginSuccess } from "../../common/features/authSlice";
import { toast } from "react-toastify";
import weLearnLogo from "../../images/logo.png";
import { validationCharactersAlphabets } from "../../constants";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [login] = useLoginUserMutation();

  const onFinish = async (values) => {
    const { username, password } = values;
    const formData = new FormData();
    formData.append("email", username);
    formData.append("password", password);

    try {
      const response = await login(formData);

      if (response?.data) {
        toast.success(response?.data?.message);
        dispatch(loginSuccess(response.data));
        sessionStorage.setItem("token", response.data.token);
        const object = JSON.parse(atob(response.data.token.split(".")[1]));
        sessionStorage.setItem("details", JSON.stringify(object));
        navigate(`/`);
      }
      if (response?.error) {
        toast.error(
          response?.error?.data?.message ?? "Please enter a valid credentials"
        );
      }
    } catch (error) {
      dispatch(loginFailure("Login failed"));
    }
  };

  return (
    <StyledLoginWrapper>
      <StyledLoginBox>
        <StyledIllustrationWrapper>
          <StyledIllustration
            src="https://mixkit.imgix.net/art/preview/mixkit-left-handed-man-sitting-at-a-table-writing-in-a-notebook-27-original-large.png?q=80&auto=format%2Ccompress&h=700"
            alt="Login"
          />
        </StyledIllustrationWrapper>
        <StyledLoginForm name="login-form" onFinish={onFinish}>
          <img src={weLearnLogo} height={100} alt="WeLearn Logo" />
          <StyledFormTitle>Welcome back</StyledFormTitle>
          <p>Login to the Dashboard</p>
          <StyledFormItem
            name="username"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please enter valid username!",
              },
            ]}
          >
            <StyledInput placeholder="Username" />
          </StyledFormItem>
          <StyledFormItem
            name="password"
            required
            rules={[
              { required: false, message: "Please enter a password" },
              {
                validator: (rule, value, callback) =>
                  validationCharactersAlphabets(
                    rule,
                    value,
                    callback,
                    "Please enter valid password!"
                  ),
              },
            ]}
          >
            <StyledPasswordInput placeholder="password" />
          </StyledFormItem>

          <StyledFormItem>
            <StyledButton
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              LOGIN
            </StyledButton>
          </StyledFormItem>
        </StyledLoginForm>
      </StyledLoginBox>
    </StyledLoginWrapper>
  );
};

export default Login;
